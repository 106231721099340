import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FiX } from "react-icons/fi";

const Footer = () => {
  return (
    <>
      <footer className="bg-[#f1f1f1] text-gray-800 py-8 flex flex-col items-center justify-center  w-full ">
        <div className="container mx-auto px-4 w-[75%]">
          <div className="flex flex-wrap justify-between text-center md:text-left">
            <div className="w-full md:w-1/3 mb-8 md:mb-0">
              <div className="mb-12 flex flex-col justify-end items-end">
                <h4 className="text-xl font-bold mb-2 text-end">اتصل بنا</h4>
                <hr className="border-t-4 border-orange-400 w-12" />
              </div>
              <p className="text-end mb-4">عنوان: كعب رقم 333</p>
              <p className="text-end mb-4">
                رقم المبنى 4123شارع ابو فراس الاسلمي حي الجمعة المدينة المنورة
              </p>
              <p className="text-end mb-4">هاتف : 0559165000</p>
              <p className="text-end mb-4">
                {" "}
                info@saudigtc.com : بريد إلكتروني
              </p>
              <div className="flex justify-end">
                <div className="flex justify-center md:justify-start space-x-4 mt-4">
                  <a href="https://www.facebook.com/">
                    <div className="w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full text-white">
                      <FaFacebookF size={18} />
                    </div>
                  </a>
                  <a
                    href="
https://l.instagram.com/?u=https%3A%2F%2Ftwitter.com%2FGtcSaudi%3Ft%3Dhmr01Akziprez7Uq3JdI_w%26s%3D09&e=AT3Z1aCUxyFyGSXvAxQWAibHfTWi802mReAFcCQu04Ffd9HH3tHRvxgmaDUpCUltTcPcrtBcp-gyUSMqzS0G779ClvzGME7KbFualcK2xmKqqYyJ
"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-black rounded-full text-white">
                      <FiX size={18} />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/saudi_gtc?igsh=MTlzMnd0OWx2a250cg=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500 rounded-full text-white">
                      <FaInstagram size={18} />
                    </div>
                  </a>
                  <a
                    href="https://l.instagram.com/?u=http%3A%2F%2Fwww.linkedin.com%2Fin%2Fsaudi-gtc-22a266282&e=AT16gnAkFcDpFUS6M0cOZEZG1_CqwOElqsNamL-Igrfp7uFWPYY55ngkSFL-JVhPfOGg2kKi9Jz-hK54J_5FkSZZIx_TXaLteBnBl3jn0vsCTkMD"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-blue-700 rounded-full text-white">
                      <FaLinkedin size={18} />
                    </div>
                  </a>
                  <a
                    href="https://www.tiktok.com/@saudi.gtc?_t=8dhTgRZSW2L&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8 flex items-center justify-center bg-black rounded-full text-white">
                      <FaTiktok size={18} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 mb-8 md:mb-0">
              <div className="mb-12 flex flex-col justify-end items-end">
                <h4 className="text-xl font-bold mb-2">روابط الشركة</h4>
                <hr className="border-t-4 border-orange-400 w-12" />
              </div>

              <ul className="text-end">
                <div class="grid grid-cols-2 mb-3">
                  <li className="mb-2">
                    <a href="#" className="hover:underline">
                      تاريخ
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#" className="hover:underline">
                      الخدمات
                    </a>
                  </li>
                </div>
                <div class="grid grid-cols-2 mb-3">
                  <li className="mb-2">
                    <a href="#" className="hover:underline">
                      امان
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#" className="hover:underline">
                      الفرق والجوائز
                    </a>
                  </li>
                </div>
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    مجتمع
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    التعليمات
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    اتصل بنا
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-8 md:mb-0">
              <div className="mb-12 flex flex-col justify-end items-end">
                <h4 className="text-xl font-bold mb-2 text-end">معلومات عنا</h4>
                <hr className="border-t-4 border-orange-400 w-12" />
              </div>
              <div className="text-end text-2xl">
                <a href="#" className="hover:underline">
                  بناء
                </a>
              </div>

              <div className="text-end">
                <p>
                  البناء ليس مجرد وظيفة في شركتنا. البناء هو حافزنا لكل ما نقوم
                  به. نحن ملتزمون لبناء وتطوير أفضل الأشخاص في الصناعة مع الحب
                  الحقيقي لما نقوم به لجعل عقباتنا رؤية حقيقة
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="flex justify-center items-center w-full h-16 bg-stone-200">
        <p>جميع الحقوق محفوظة 2024</p>
      </div>
    </>
  );
};

export default Footer;
