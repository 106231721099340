import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import "./style.css";
import ContactUsForm from "./ContactUsForm";

const ContactSection = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_70uxdpb", // Replace with your service ID
        "template_p7e5139", // Replace with your template ID
        form.current,
        "06089V8jH2QpJ9SLb" // Replace with your user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("تم ارسال الرسالة", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("خطأ في ارسال الرسالة", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );

    e.target.reset();
  };

  return (
    <div>
      <ToastContainer /> {/* Ensure ToastContainer is here */}
      <div className="bg-cover bg-center relative bg-contact h-[100vh]">
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="container mx-auto w-[75%] h-[90vh] flex flex-col justify-center px-4 py-16 lg:py-32 relative z-10 text-white">
          <div className="text-end mb-8 pt-16">
            <h2 className="text-5xl font-bold mb-4">اتصل بنا</h2>
            <p className="text-[16px]">
              للمقاولات نتطلع دائمًا إلى سماع رأيك، سواء كنت تبحث GTC نحن في
              مؤسسة
            </p>
            <p className="text-[16px]">
              إذا كنت ترغب في طرح أسئلة حول خدماتنا، فإن فريقنا جاهز لتقديم
              الدعم الذي تحتاجه
            </p>
            <p className="text-[16px]">
              لا يوجد مشروع صغير جدًا بحيث لا يمكن تنفيذه
            </p>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col justify-end items-end relative z-[99999]"
          >
            <div className="mb-4 w-full max-w-[28rem]">
              <input
                type="text"
                name="to_title"
                className="w-full p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
                placeholder="عنوان"
                required
              />
            </div>
            <div className="mb-4 w-full max-w-[28rem]">
              <input
                type="text"
                name="phone"
                className="w-full p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
                placeholder="هاتف"
                required
              />
            </div>
            <div className="mb-4 w-full max-w-[28rem]">
              <input
                type="email"
                name="to_email"
                className="w-full p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
                placeholder="بريد إلكتروني"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-8 rounded"
            >
              قدم
            </button>
          </form>
        </div>
      </div>

      <div
        className="bg-green-600 text-white font-bold text-xl flex justify-center items-center 
      pt-5 pb-5 lg:pt-10 lg:pb-10 xl:pt-10 xl:pb-10"
      >
        <p>المكاتب الفرعية</p>
      </div>

      <ContactUsForm />
    </div>
  );
};

export default ContactSection;
