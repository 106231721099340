import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import SportsBasketballOutlinedIcon from "@mui/icons-material/SportsBasketballOutlined";
import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";

const Navbar = () => {
  return (
    <footer className="bg-orange-500 text-white py-4 px-4 md:px-8 w-full">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center w-[90%]">
        <div className="flex space-x-4 mb-4 md:mb-0">
          <a
            href="https://l.instagram.com/?u=https%3A%2F%2Ftwitter.com%2FGtcSaudi%3Ft%3Dhmr01Akziprez7Uq3JdI_w%26s%3D09&e=AT3Z1aCUxyFyGSXvAxQWAibHfTWi802mReAFcCQu04Ffd9HH3tHRvxgmaDUpCUltTcPcrtBcp-gyUSMqzS0G779ClvzGME7KbFualcK2xmKqqYyJ"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <TwitterIcon sx={{ fontSize: 20 }} />
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <FacebookIcon sx={{ fontSize: 20 }} />
          </a>
          <a
            href="https://google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <GoogleIcon sx={{ fontSize: 20 }} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300"
          >
            <SportsBasketballOutlinedIcon sx={{ fontSize: 20 }} />
          </a>
        </div>
        <div className="flex flex-col md:flex-row items-center text-center md:text-left space-y-2 md:space-y-0 md:space-x-4">
          <span className="flex items-center">
            <CallRoundedIcon sx={{ fontSize: 20 }} className="mr-2" />
            0559165000{" "}
          </span>
          <span className="flex items-center">
            <DraftsRoundedIcon sx={{ fontSize: 20 }} className="mr-2" />
            info@saudigtc.com
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Navbar;
