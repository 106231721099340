import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FiX } from "react-icons/fi";

const Footer2 = () => {
  return (
    <>
      <footer className="bg-gray-100 py-12 w-full">
        <div
          className="container mx-auto px-4 flex flex-col md:flex-col lg:flex-row xl:flex-row justify-between
         items-end sm:items-end md:items-end lg:items-start 
        xl:items-start w-[75%]"
        >
          <div className="text-end mb-2 mt-2 sm:mb-2 sm:mt-2 md:mt-2 md:mb-2 lg:m-0 xl:m-0">
            <p className="text-xl font-bold mb-4">تابعنا</p>

            <div className="flex justify-center md:justify-start space-x-4 mt-4">
              <a href="https://www.facebook.com/">
                <div className="w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full text-white">
                  <FaFacebookF size={18} />
                </div>
              </a>
              <a
                href="
https://l.instagram.com/?u=https%3A%2F%2Ftwitter.com%2FGtcSaudi%3Ft%3Dhmr01Akziprez7Uq3JdI_w%26s%3D09&e=AT3Z1aCUxyFyGSXvAxQWAibHfTWi802mReAFcCQu04Ffd9HH3tHRvxgmaDUpCUltTcPcrtBcp-gyUSMqzS0G779ClvzGME7KbFualcK2xmKqqYyJ
"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-black rounded-full text-white">
                  <FiX size={18} />
                </div>
              </a>
              <a
                href="https://www.instagram.com/saudi_gtc?igsh=MTlzMnd0OWx2a250cg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500 rounded-full text-white">
                  <FaInstagram size={18} />
                </div>
              </a>
              <a
                href="https://l.instagram.com/?u=http%3A%2F%2Fwww.linkedin.com%2Fin%2Fsaudi-gtc-22a266282&e=AT16gnAkFcDpFUS6M0cOZEZG1_CqwOElqsNamL-Igrfp7uFWPYY55ngkSFL-JVhPfOGg2kKi9Jz-hK54J_5FkSZZIx_TXaLteBnBl3jn0vsCTkMD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-blue-700 rounded-full text-white">
                  <FaLinkedin size={18} />
                </div>
              </a>
              <a
                href="https://www.tiktok.com/@saudi.gtc?_t=8dhTgRZSW2L&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="w-8 h-8 flex items-center justify-center bg-black rounded-full text-white">
                  <FaTiktok size={18} />
                </div>
              </a>
            </div>
          </div>
          <div className="text-end mb-2 mt-2 sm:mb-2 sm:mt-2 md:mt-2 md:mb-2 lg:m-0 xl:m-0">
            <p className="text-xl font-bold mb-4 text-end">بريد إلكتروني</p>
            <p className="text-gray-600">info@saudigtc.com</p>
          </div>
          <div className="text-end mb-2 mt-2 sm:mb-2 sm:mt-2 md:mt-2 md:mb-2 lg:m-0 xl:m-0">
            <p className="text-xl font-bold mb-4 text-end">هاتف</p>
            <p className="text-gray-600">0559165000</p>
          </div>
          <div className="text-end mb-2 mt-2 sm:mb-2 sm:mt-2 md:mt-2 md:mb-2 lg:m-0 xl:m-0  xl:w-[20%] lg:w-[20%]">
            <p className="text-xl font-bold mb-4 text-end">عنوان</p>
            <p className="text-gray-600 ">
              مكتب رقم 333 قم المبنى 5142 شارع نؤ نرأيس السالمس حي الجمعة
              المدينة المنورة
            </p>
          </div>
        </div>
      </footer>
      <div className="flex justify-center items-center w-full h-16 bg-stone-200">
        <p>جميع الحقوق محفوظة 2024</p>
      </div>
    </>
  );
};

export default Footer2;
