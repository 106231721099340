import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".menu") &&
        !event.target.closest(".menu-button")
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <header className="bg-white shadow-md z-50 w-full ">
      <div className="container mx-auto flex justify-between items-center w-[85%] ">
        <div className="flex items-center" onClick={() => navigate("/")}>
          <img
            src={Logo}
            alt="Logo"
            className="w-[90px] cursor-pointer relative z-[9999]"
          />
        </div>
        <div className="flex items-center space-x-4 mx-4">
          <button
            className="menu-button md:hidden text-gray-800 focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5m-16.5 5.25h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <nav className="hidden md:flex space-x-4">
            <div
              onClick={() => handleNavigation("/")}
              className="cursor-pointer mx-4 md:mx-0 xs:mt-10"
            >
              <p className="text-gray-800 hover:text-gray-600">الرئيسية</p>
            </div>
            <div
              onClick={() => handleNavigation("/about")}
              className="cursor-pointer mx-4 md:mx-0"
            >
              <p className="text-gray-800 hover:text-gray-600">من نحن</p>
            </div>
            <div
              onClick={() => handleNavigation("/services")}
              className="cursor-pointer mx-4 md:mx-0"
            >
              <p className="text-gray-800 hover:text-gray-600">خدماتنا</p>
            </div>
            <div
              onClick={() => handleNavigation("/contact")}
              className="cursor-pointer mx-4 md:mx-0"
            >
              <p className="text-gray-800 hover:text-gray-600">اتصل بنا</p>
            </div>
          </nav>
          <div className="relative hidden md:block">
            <input
              type="text"
              placeholder="بحث"
              className="px-4 py-2 border rounded-full outline-none focus:ring-2 focus:ring-gray-400"
            />
            <button className="absolute right-2 top-2 text-gray-400 hover:text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-500 ease-in-out z-40 ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden menu`}
      >
        <nav className="flex flex-col p-4 space-y-4">
          <div onClick={() => handleNavigation("/")} className="cursor-pointer">
            <p className="text-gray-800 hover:text-gray-600">الرئيسية</p>
          </div>
          <div
            onClick={() => handleNavigation("/about")}
            className="cursor-pointer"
          >
            <p className="text-gray-800 hover:text-gray-600">من نحن</p>
          </div>
          <div
            onClick={() => handleNavigation("/services")}
            className="cursor-pointer"
          >
            <p className="text-gray-800 hover:text-gray-600">خدماتنا</p>
          </div>
          <div
            onClick={() => handleNavigation("/contact")}
            className="cursor-pointer"
          >
            <p className="text-gray-800 hover:text-gray-600">اتصل بنا</p>
          </div>
          <input
            type="text"
            placeholder="بحث"
            className="px-4 py-2 border rounded-full outline-none focus:ring-2 focus:ring-gray-400 mt-4"
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
