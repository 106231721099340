import React, { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    city1: "",
    phone1: "",
    city2: "",
    phone2: "",
    city3: "",
    phone3: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_70uxdpb", // Replace with your service ID
        "template_p7e5139", // Replace with your template ID
        {
          city1: formData.city1,
          phone1: formData.phone1,
          city2: formData.city2,
          phone2: formData.phone2,
          city3: formData.city3,
          phone3: formData.phone3,
        },
        "06089V8jH2QpJ9SLb" // Replace with your user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("تم ارسال الرسالة", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("خطأ في ارسال الرسالة", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );

    setFormData({
      city1: "",
      phone1: "",
      city2: "",
      phone2: "",
      city3: "",
      phone3: "",
    });
  };

  return (
    <div className="mt-12 mb-12">
      <div className="flex flex-col md:flex-row justify-center align-center gap-12 mb-8 ms-[40px] me-[40px]">
        <input
          type="text"
          name="city1"
          value={formData.city1}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="عنوان ينبع"
        />
        <input
          type="text"
          name="phone1"
          value={formData.phone1}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="رقم التليفون"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center align-center gap-12 mb-8 ms-[40px] me-[40px]">
        <input
          type="text"
          name="city2"
          value={formData.city2}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="عنوان الدمام"
        />
        <input
          type="text"
          name="phone2"
          value={formData.phone2}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="رقم التليفون"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-center align-center gap-12 mb-8 ms-[40px] me-[40px]">
        <input
          type="text"
          name="city3"
          value={formData.city3}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="عنوان الرياض"
        />
        <input
          type="text"
          name="phone3"
          value={formData.phone3}
          onChange={handleChange}
          className="w-full max-w-[28rem] sm:max-w-[40rem] md:max-w-md lg:max-w-[24rem] xl:max-w-[20rem] p-3 border rounded border-gray-400 focus:outline-none focus:border-gray-400 placeholder:text-end text-black"
          placeholder="رقم التليفون"
        />
      </div>

      <div className="w-full mt-6 flex justify-center">
        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-8 rounded"
        >
          يقدم
        </button>
      </div>
    </div>
  );
};

export default ContactUsForm;
