import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/navbar";
import "./App.css";
import Header from "./Components/Header/header";
import Footer from "./Components/Footer/Footer";
import Spinner from "./Components/Loading/Loading";
import ContactSection from "./Pages/ContactUs/ContactUs";
import Footer2 from "./Components/Footer2/Footer2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS
import AdSenseAd from "./Components/Ads/AdscenceAds";

const HomePage = lazy(() => import("./Pages/Home/HomePage"));
const Services = lazy(() => import("./Pages/Services/Services"));
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));

const App = () => {
  const location = useLocation();
  return (
    <div>
      {/* <AdSenseAd /> */}
      <Navbar />
      <Header />
      <ToastContainer />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Suspense>
      {location.pathname === "/contact" ? (
        <>
          <Footer2 />
        </>
      ) : location.pathname === "/services" ? (
        <>
          <Footer2 />
        </>
      ) : (
        <>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
